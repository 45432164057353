import React from "react";
import "./Home.css";

function Home() {
  return (
    <div className="Home">
      <span className="Home-logo-label">Luavi</span>
    </div>
  );
}

export default Home;
