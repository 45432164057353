import React from "react";
import "./App.css";
import Home from "../Home/Home";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <span>Coming soon</span>
      </header>
      <div className="App-body">
        <Home />
      </div>
      <footer className="App-footer">
        <span>luavi.world | 2023</span>
      </footer>
    </div>
  );
}

export default App;
